import Cookies from 'js-cookie';

interface RegisterData {
    name: string;
    lastname: string;
    email: string;
    password: string;
    phonenumber?: string; // Propriété facultative
    profilePhoto?: string; // Propriété facultative pour l'image de profil
}

async function FetchFile(url: string, method: string, contentType: string, csrfToken: string, body?: any) {
    console.log('methode:', method)
    const options: RequestInit = {
        method,
        headers: {
            'Authorization': "Bearer " + Cookies.get('authToken'),
            'X-CSRF-Token': csrfToken,
            'x-mobile-app': 'true'
        },
        credentials: 'include',
        body: null
    };

    if (body) {
        if (contentType === 'multipart/form-data') {
            options.body = body;
        } else {
            options.headers = { ...options.headers, 'Content-Type': contentType };
            options.body = JSON.stringify(body);
        }
    }
    const res = await fetch(`https://alfred-app.fr/api-file/${url}`, options);

    console.log(`[API] [${new Date().toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}] [${method}] /${url} (${res.status})`);
    if (res.status != 200){
        console.log('error:', res)
        throw 'error';
    }
    const json = await res.json();
    if (json.error) {
        console.log('error:', json.message)
        throw new Error(json.message);
    }
    console.log('json: ', json)
    return json;
}


export class ApiFile {
    static async getFile() {
        const documents = await FetchFile('file', 'GET', 'application/json', Cookies.get('csrfToken')!);
        return documents;
    }

    static async getStorage() {
        const json = await FetchFile('storage/info', 'GET', 'application/json', Cookies.get('csrfToken')!);
        return json
    }

    static async uploadFiles(files: File[]) {
        const formData = new FormData();

        // Ajout des fichiers dans le FormData
        files.forEach(file => {
            formData.append('files', file);
        });

        const json = await FetchFile('file/upload/multi', 'POST', 'multipart/form-data', Cookies.get('csrfToken')!, formData);
        return json;
    }

    static async deleteFile(id: string) {
        const response = await FetchFile(`file/${id}`, 'DELETE', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async deleteScenario(id: string) {
        const response = await FetchFile(`scenario/${id}`, 'DELETE', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async downloadFile(id: string) {
        console.log('test')
        const response = await FetchFile(`file/download/get/id/${id}`, 'get', 'multipart/form-data', Cookies.get('csrfToken')!);
        return response;
    }

    static async getFilebyId(id: string) {
        const response = await FetchFile(`file/${id}`, 'GET', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async ChangeFavorie(id: string) {
        const response = await FetchFile(`favorie/${id}`, 'GET', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async GetPreview(id: string): Promise<{ data: Blob, headers: Headers }> {
        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'multipart/form-data');
        customHeaders.append('Authorization', `Bearer ${Cookies.get('authToken')}`);
        const response = await fetch(`https://alfred-app.fr/api-file/file/download/get/id/${id}`, { method: 'GET', headers: customHeaders });
        if (response.ok) {
            const data = await response.blob(); // Convert response to blob for file display
            return {
                data,
                headers: response.headers
            };
        } else {
            throw new Error('Failed to download file');
        }
    }

    static async getSenario() {
        const response = await FetchFile(`scenario`, 'GET', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async getOneSenario(id: string) {
        const response = await FetchFile(`scenario/` + id, 'GET', 'application/json', Cookies.get('csrfToken')!);
        return response;
    }

    static async PutFile(body: any) {
        const response = await FetchFile(`file`, 'PUT', 'application/json', Cookies.get('csrfToken')!, body);
        return response;
    }

    static async DownloadScenario(file: string[]) {
        const customHeaders = new Headers();
        customHeaders.append('Content-Type', 'application/json'); // Utiliser 'Content-Type' et non 'content'
        customHeaders.append('Accept', 'application/zip'); // Gérer les téléchargements ZIP
        customHeaders.append('Authorization', `Bearer ${Cookies.get('authToken')}`); // Utiliser le token d'authentification
    
        const body = JSON.stringify({
            idFileType: file,
        });
    
        console.log('Request body:', body);
    
        try {
            const response = await fetch(`https://alfred-app.fr/api-file/scenario/download`, {
                method: 'POST',
                headers: customHeaders,
                body: body,
            });
    
            if (response.ok) {
                console.log('Fichier téléchargé avec succès');
                const blob = await response.blob(); // Convertir la réponse en Blob pour le téléchargement
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'scenario.zip'); // Nommer le fichier téléchargé
                document.body.appendChild(link);
                link.click();
                link.parentNode?.removeChild(link); // Supprimer le lien après le téléchargement
            } else {
                const errorMessage = await response.text(); // Récupérer le texte de l'erreur depuis le serveur
                console.error('Erreur:', errorMessage);
                throw new Error(`Échec du téléchargement: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error('Erreur lors de la requête:', error);
            alert('Erreur lors du téléchargement des fichiers. Vérifiez la console pour plus d\'informations.');
        }
    }
}