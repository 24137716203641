// register

import React from 'react'
import logo from '../icon/Alfred_lineWhite.png'

import RegisterBox from '../components/RegisterBox';


export default function Register() {
    return (
        <div className="login-container">
            <img
                src={logo}
                alt="Register"
                className="login-image"
            />
            <RegisterBox /> {/* Utiliser LoginBox ici */}
        </div>
    )
}
