import React from 'react';

import Confirm2faBoxCode from './Confirm2faBoxCode';

export default function Confirmation2faBox() {
    return (
        <div className="login-box">
            <p className="text-Connexion">Confirmation 2FA</p>
            <p className='resend-text'>
                Veuillez entrer le code que nous vous avons envoyé.
            </p>
            <Confirm2faBoxCode />

        </div>
    );
}