import React, { useState, useEffect } from 'react';
import downArrow from '../icon/down_arrow.svg';
import StockageContainer from '../components/StockgeContainer';
import { ApiUser } from '../api/ApiUser';
import france from "../icon/france.svg"
import english from "../icon/united.svg"
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const mockedData = {
  nom: "",
  prenom: "",
  email: "",
  telephone: "",
};

const lastActivityData = [
  { id: "1", name: "Document 1", date: "JJ/MM/AAAA" },
  { id: "2", name: "Document 2", date: "JJ/MM/AAAA" },
  { id: "3", name: "Document 3", date: "JJ/MM/AAAA" },
  { id: "4", name: "Document 4", date: "JJ/MM/AAAA" },
  { id: "5", name: "Document 5", date: "JJ/MM/AAAA" },
  { id: "6", name: "Document 6", date: "JJ/MM/AAAA" },
];

const InvoiceItem: React.FC<{ name: string; date: string }> = ({ name, date }) => {
  return (
    <li>
      <div>Vous avez ajouté {name}</div>
      <div className="date-text">{date}</div>
    </li>
  );
};

export default function Profile() {
  const [isDropdownOpenInformation, setIsDropdownOpenInformation] = useState(false);
  const [isDropdownOpenStockage, setIsDropdownOpenStockage] = useState(false);
  const [isDropdownOpenSettings, setIsDropdownOpenSettings] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(mockedData); // State for form data
  const [originalData, setOriginalData] = useState(mockedData); // State for original data
  const [User, setUser] = useState<any | null>(null); // State for original data
  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const Userdata = await ApiUser.getUser();
        const user = {
          nom: Userdata.name.first,
          prenom: Userdata.name.last,
          email: Userdata.email.address,
          telephone: Userdata.phone ? Userdata.phone : '',
        };
        setFormData(user)
        setIsTwoFaEnabled(Userdata._2FA)
        setOriginalData(user)
      } catch (error) {
        alert('erreur lors du fetch des informations')
      }
    }
    fetchUser();
  }, [])

  const toggleDropdownInformation = () => {
    setIsDropdownOpenInformation(!isDropdownOpenInformation);
  };

  const toggleDropdownStockage = () => {
    setIsDropdownOpenStockage(!isDropdownOpenStockage);
  };

  const toggleDropdownSettings = () => {
    setIsDropdownOpenSettings(!isDropdownOpenSettings);
  };

  const toggleTwoFa = () => {
    const enable = !isTwoFaEnabled;
    ApiUser.Set2FA(enable);
    setIsTwoFaEnabled(!isTwoFaEnabled);
    // Here, you could add an API call to update the 2FA status on the server.
  };

  const ToggleLogout = () => {
    Cookies.remove('authToken');
    Cookies.remove('refreshToken');
    Cookies.remove('idUser');
    navigate('/')
    // Here, you could add an API call to update the 2FA status on the server.
  };

  const handleModifierClick = () => {
    if (!isEditing) {
      setOriginalData(formData); // Save the original data before editing
    }
    setIsEditing(!isEditing); // Toggle edit mode
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); // Update form data
  };

  const handleSave = async () => {

    try {
      const updatedUser = await ApiUser.updateUser(
        formData.nom || undefined,
        formData.prenom || undefined,
        formData.email || undefined,
        formData.telephone || undefined
      );
      console.log("Saved Data:", formData); // You can add logic to save the data
      setIsEditing(false); // Exit edit mode after saving
      console.log('Updated User:', updatedUser);
    } catch (error) {
      console.error('Error updating user:', error);
    }

  };

  const handleCancel = () => {
    setFormData(originalData); // Restore the original data
    setIsEditing(false); // Exit edit mode
  };

  return (
    <div className='profile-page'>
      <div className='profile-container'>
        <div className="page-title">Mon compte</div>

        {/* Personal Information Section */}
        <div className='profile-info-container'>
          <div className='profile-info-title'>
            <div className="myInfo-text">Mes informations personnelles</div>
            <button className='downArrowBtn' onClick={toggleDropdownInformation}>
              <img className='downArrow' src={downArrow} alt="Toggle dropdown" />
            </button>
          </div>

          {isDropdownOpenInformation && (
            <div className='dropdown-content'>
              <div className='information-text'>
                {isEditing ? (
                  <>
                    <div className="info-row">
                      <strong>Nom:</strong>
                      <input
                        type="text"
                        name="nom"
                        value={formData.nom}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="info-row">
                      <strong>Prénom:</strong>
                      <input
                        type="text"
                        name="prenom"
                        value={formData.prenom}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="info-row">
                      <strong>Email:</strong>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="info-row">
                      <strong>Téléphone:</strong>
                      <input
                        type="tel"
                        name="telephone"
                        value={formData.telephone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </>
                ) : (
                  Object.entries(formData).map(([key, value]) => (
                    <div key={key} className="info-row">
                      <strong>{key.charAt(0).toUpperCase() + key.slice(1)}:</strong> {value}
                    </div>
                  ))
                )}
              </div>

              <div className='button-container'>
                {isEditing ? (
                  <>
                    <button className='annuler-button' onClick={handleCancel}>Annuler</button>
                    <button className='modifier-button' onClick={handleSave}>Enregistrer</button>
                  </>
                ) : (
                  <button className='modifier-button' onClick={handleModifierClick}>Modifier</button>
                )}
              </div>
            </div>
          )}
        </div>

        {/* Subscription Section */}
        <div className="profile-info-container">
          <div className='profile-info-title'>
            <div className="myInfo-text">Mon abonnement</div>
            <button className='downArrowBtn' onClick={toggleDropdownStockage}>
              <img className='downArrow' src={downArrow} alt="Toggle dropdown" />
            </button>
          </div>

          {isDropdownOpenStockage && (
            <div className='dropdown-content'>
              <StockageContainer />
              <div className='button-container'>
                <button className='modifier-button'>Gérer mon abonnement</button>
              </div>
            </div>
          )}
        </div>


        <div className="profile-info-container">
          <div className='profile-info-title'>
            <div className="myInfo-text">Mes Réglages</div>
            <button className='downArrowBtn' onClick={toggleDropdownSettings}>
              <img className='downArrow' src={downArrow} alt="Toggle dropdown" />
            </button>
          </div>

          {isDropdownOpenSettings && (
            <div className='dropdown-content'>
              <div className="langue-container">
                <span>langue :</span>
                <div className="langue-flags">
                  <img src={france} alt="France" className="flag-icon" />
                  <img src={english} alt="English" className="flag-icon" />
                </div>
              </div>
              <div className="twofa-container">
                <span>2FA (double authentication) :</span>
                <button
                  className={`twofa-button ${isTwoFaEnabled ? 'disable' : ''}`}
                  onClick={toggleTwoFa}
                >
                  {isTwoFaEnabled ? 'Disable' : 'Enable'}
                </button>
              </div>
              <div className='button-container'>
                <button className='logout-button' onClick={ToggleLogout}>Se déconnecter</button>
              </div>
            </div>
          )}

        </div>
      </div>


      <div className='lastActivitySideBar'>
        <div className='lastActivityTitle'>Dernière Activité</div>
        <ul>
          {lastActivityData.map(({ id, name, date }) => (
            <InvoiceItem key={id} name={name} date={date} />
          ))}
        </ul>
      </div>
    </div>
  );
}
