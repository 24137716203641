import React from 'react';


export default function Offer() {
    return (
        <div className="offer-Container">
            <button className="arrow-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 19L8 12L15 5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
            <div className="offer-box">
                <div className="offer-text">Nouvelles offres!</div>
                <div>Découvrez les nouvelles offres disponibles spécialement pour vous.
                    Vous permettant d'économiser de l'argent sur vos factures.</div>
                <button className="discover-button">Découvrir</button>
            </div>
            <button className="arrow-button">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 5L16 12L9 19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </div>
    );
}