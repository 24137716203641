import React, { useState } from 'react';
import { ApiUser } from '../api/ApiUser';
import { useNavigate } from 'react-router-dom';

export default function LoginBox() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Initialisation du hook useNavigate

  const handleLogin = () => {
    if (email && password) {
      // Remplacez par la logique réelle de connexion
      ApiUser.login(email, password)
        .then((response) => {
          console.log('response:', response)
          if (!response.token) {
            navigate('/login2FA')
          } else {
            navigate('/documents');
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert('Veuillez remplir tous les champs.');
    }
  };

  return (
    <div className="login-box">
      <p className="text-Connexion">Se connecter</p>
      <p className='signup-text'>
        Vous n'avez pas de compte ?{' '}
        <button className="signup-button" onClick={() => navigate('/register')}>
          Inscrivez-vous
        </button>
      </p>
      <input
        type="email"
        placeholder="email@adresse.com"
        className="input"
        value={email}
        onChange={(e) => setEmail(e.target.value)} // Capture email
      />
      <input
        type="password"
        placeholder="password"
        className="input"
        value={password}
        onChange={(e) => setPassword(e.target.value)} // Capture mot de passe
      />
      <button className="connect-button" onClick={handleLogin}>
        Se connecter
      </button>
      <button className="signup-ForgetPassword" onClick={() => alert('Mot de passe oublié ?')}>
        Mot de passe oublié ?
      </button>
    </div>
  );
}

