import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import React from 'react';
import ReactDOM from 'react-dom/client';
import AppRouter from './router';

function App() {
  return (
    <React.StrictMode>
    <AppRouter />  {/* This renders the router or your App component */}
  </React.StrictMode>
  );
}

export default App;