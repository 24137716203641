import React from 'react';
import Sidebar from '../components/Sidebar'; // Assurez-vous que votre composant Sidebar est bien importé

type SidebarLayoutProps = {
  children: React.ReactNode;
};

const SidebarLayout: React.FC<SidebarLayoutProps> = ({ children }) => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="content">
        {children}
      </div>
    </div>
  );
};

export default SidebarLayout;
