// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';  // This is importing your main App component
// import './index.css';     // Import your CSS if needed, or remove if not necessary
// import AppRouter from './router'; // Import your Router file (assuming you have a router.tsx)
import '../src/styles/main.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <App/>
);
