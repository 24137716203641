import React, { CSSProperties, useState, useRef, Suspense } from 'react';
// import useMapping from '../../helpers/utils/Mapping';
import { useNavigate } from 'react-router-dom';
// import SignaturePad from '../../components/signature/SignaturePad';
// import ToasterBeta from '../../components/toaster/ToasterBeta';

// const SignaturePad = React.lazy(() => import('../../components/signature/SignaturePad'));


// Mock subscription data

type Subscription = {
    name: string;
    price: string;
    description: string;
    detailedDescription: string;
    advantages: string[];
    keyFeatures: string[];
  };
const subscriptions: Subscription[] = [
    { 
      name: 'Subscription 1', 
      price: '$9.99/month', 
      description: 'Basic plan with limited features.', 
      detailedDescription: 'This plan includes basic features and is suitable for individuals or small businesses looking to get started with our service.',
      advantages: [
        'Advantage 1 for Subscription 1',
        'Advantage 2 for Subscription 1',
        'Advantage 3 for Subscription 1',
      ],
      keyFeatures: [
        'Basic feature set',
        'Limited support options',
        'Access to community forums',
        'Monthly updates and bug fixes',
      ],
    },
    { 
      name: 'Subscription 2', 
      price: '$19.99/month', 
      description: 'Standard plan with additional features.', 
      detailedDescription: 'Upgrade to our standard plan for additional features and enhanced support. Perfect for growing businesses.',
      advantages: [
        'Advantage 1 for Subscription 2',
        'Advantage 2 for Subscription 2',
        'Advantage 3 for Subscription 2',
      ],
      keyFeatures: [
        'Enhanced feature set',
        'Priority email support',
        'Access to tutorials and webinars',
        'Quarterly performance reports',
        'Customizable dashboard',
      ],
    },
    { 
      name: 'Subscription 3', 
      price: '$29.99/month', 
      description: 'Premium plan with all features included.', 
      detailedDescription: 'Unlock all features with our premium plan. Ideal for businesses seeking advanced functionality and priority support.',
      advantages: [
        'Advantage 1 for Subscription 3',
        'Advantage 2 for Subscription 3',
        'Advantage 3 for Subscription 3',
      ],
      keyFeatures: [
        'Comprehensive feature set',
        '24/7 priority support',
        'Access to API integrations',
        'Advanced analytics and reporting',
        'Dedicated account manager',
      ],
    },
    { 
      name: 'Subscription 4', 
      price: '$39.99/month', 
      description: 'Ultimate plan with exclusive features and support.', 
      detailedDescription: 'Get access to our ultimate plan for exclusive features, priority support, and dedicated account management. Perfect for enterprise-level needs.',
      advantages: [
        'Advantage 1 for Subscription 4',
        'Advantage 2 for Subscription 4',
        'Advantage 3 for Subscription 4',
      ],
      keyFeatures: [
        'Exclusive feature set',
        '24/7 premium support with SLA',
        'Access to beta features',
        'Customizable analytics dashboard',
        'Integration with CRM systems',
        'Enterprise-level security features',
      ],
    },
  ];

const Subscription: React.FC = () => {
  const [selectedSubscription, setSelectedSubscription] = useState<number>(0); // Initialize with the index of the first subscription
  const navigate = useNavigate();
  const learnMoreRef = useRef<HTMLDivElement>(null);


  const handleConfirmButtonClick = () => {
    // Implement logic for handling confirm button click here
  };

  const handleCrossButtonClick = () => {
    navigate('/dashboard');
  };

  const handleGetStartedClick = (index: number) => {
    console.log(`Get Started clicked for subscription ${index}`);
  };

  const handleLearnMoreClick = (index: number) => {
    console.log(`Learn More clicked for subscription ${index}`);
    setSelectedSubscription(index);
    if (learnMoreRef.current) {
      learnMoreRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const keyframes = `
    @keyframes slide-in-right {
      0% {
        transform: translateX(100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
    @keyframes slide-in-left {
      0% {
        transform: translateX(-100%);
        opacity: 0;
      }
      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }
  `;

  const handleSubscriptionSelect = (index: number) => {
    setSelectedSubscription(index);
  };

  const renderSubscription = (subscription: Subscription, index: number) => {
    return (
      <div
        key={index}
        className={`subscription ${selectedSubscription === index ? 'selected' : ''} ${index % 2 === 0 ? 'slide-in-left' : 'slide-in-right'}`}
        onClick={() => setSelectedSubscription(index)}
      >
        <div>
          <div className="subscription-name">{subscription.name}</div>
          <hr className="divider" />
          <div className="price">{subscription.price}</div>
          <hr className="divider" />
          <div>{subscription.description}</div>
          <div>{subscription.detailedDescription}</div>
        </div>
        <div className="button-container">
          <button className="button" onClick={() => handleGetStartedClick(index)}>Get Started</button>
          <button className="button" onClick={() => handleLearnMoreClick(index)}>Learn More</button>
        </div>
      </div>
    );
  };

  return (
    <div className="page">
      <div className="cross-button" onClick={handleCrossButtonClick}>&#x2715;</div>
      <div className="txt">How much does it cost?</div>
      <div className="title">Subscriptions for every need</div>
      <div className="txtBis">Every subscription plan for every budget!</div>
      <div className="subscription-container">
        {subscriptions.map((subscription, index) => renderSubscription(subscription, index))}
      </div>
      <div className="confirm-button" onClick={handleConfirmButtonClick}>Continue</div>
    </div>
  );
};


export default Subscription;
