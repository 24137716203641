import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

interface Scenario {
  id: string;
  title: string;
  description: string;
  image: string; // Add image URL property to the scenario
}

interface CardScenariosProps {
  scenarios: Scenario[];
}

export default function CardScenarios({ scenarios }: CardScenariosProps) {
  const navigate = useNavigate();

  return (
    <div className="card-grid">
      {scenarios.map((scenario, index) => (
        <div className="card-scenario" key={index} onClick={() => {navigate('/scenarios/'+scenario.id);}}>
          <div className="card-image">
            <img src={scenario.image} alt={scenario.title} />
          </div>
          <div className="card-content">
            <h3>{scenario.title}</h3>
            <p>{scenario.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
