import React, { useState } from 'react';
import backArrow from '../../icon/arrow-back.svg';
import check from '../../icon/Check.svg';
import plus from "../../icon/Plus.svg";
import trash from "../../icon/Trash.svg";
import { useNavigate } from 'react-router-dom';

// Define the type for each item in dropdownCheckboxes
type DropdownCheckbox = {
  dropdownValue: string;
  isChecked: boolean;
};

export default function NewScenario() {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [dropdownCheckboxes, setDropdownCheckboxes] = useState<DropdownCheckbox[]>([
    { dropdownValue: '', isChecked: false },
  ]);

  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/scenarios');
  };

  const handleDropdownChange = (index: number, value: string) => {
    const updatedDropdownCheckboxes = dropdownCheckboxes.map((item, i) =>
      i === index ? { ...item, dropdownValue: value } : item
    );
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedDropdownCheckboxes = dropdownCheckboxes.map((item, i) =>
      i === index ? { ...item, isChecked: !item.isChecked } : item
    );
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };

  const addDropdownCheckbox = () => {
    setDropdownCheckboxes([
      ...dropdownCheckboxes,
      { dropdownValue: '', isChecked: false },
    ]);
  };

  const handleRemoveDropdownCheckbox = (index: number) => {
    const updatedDropdownCheckboxes = dropdownCheckboxes.filter((_, i) => i !== index);
    setDropdownCheckboxes(updatedDropdownCheckboxes);
  };

  return (
    <div className='Newscenario-main-page'>
      <div className='Newscenarioheader'>
        <div className='Newscenario-left-header'>
          <div className='text-item'>
            <img src={backArrow} onClick={handleBackClick} alt="Back Arrow" className='back-arrow' />
          </div>
          <div className='text-item-header'>Nom du Scénarios</div>
        </div>
        <div className='text-item'>
          <button className='check-button'>
            <img src={check} alt="Check Icon" className='check-icon' />
          </button>
        </div>
      </div>

      <div className='Newscenario-form-container'>
        <input
          type="text"
          value={input1}
          onChange={(e) => setInput1(e.target.value)}
          placeholder="Enter value for test 1"
          className="input-field"
        />
        <input
          type="text"
          value={input2}
          onChange={(e) => setInput2(e.target.value)}
          placeholder="Enter value for test 2"
          className="input-field"
        />

        {dropdownCheckboxes.map((item, index) => (
          <div key={index} className="dropdown-checkbox-container">
            <select
              value={item.dropdownValue}
              onChange={(e) => handleDropdownChange(index, e.target.value)}
              className="dropdown-field"
            >
              <option value="">Select an option</option>
              <option value="option1">Option 1</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
            
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={item.isChecked}
                onChange={() => handleCheckboxChange(index)}
                className="checkbox-input"
              />
            </label>

            {/* Trash button */}
            <button onClick={() => handleRemoveDropdownCheckbox(index)} className="trash-button">
              <img src={trash} alt="Trash Icon" className="trash-icon" />
            </button>
          </div>
        ))}

        <button onClick={addDropdownCheckbox} className="plus-button">
          <img src={plus} alt="Plus Icon" className="plus-icon" />
        </button>
      </div>
    </div>
  );
}

// CSS styles (assuming you place these in an accompanying CSS or SCSS file)
