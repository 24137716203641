import React from 'react'
import logo from "../icon/Alfred_lineWhite.png"

import ConfirmationBox from '../components/ConfirmationBox';


export default function ConfirmatioMail() {
  return (
    <div className="login-container">
      <img
        src={logo}
        alt="Confirme"
        className="login-image"
      />
      <ConfirmationBox /> {/* Utiliser LoginBox ici */}
    </div>
  )
}
