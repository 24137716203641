//login 

import React from 'react'
import LoginBox  from '../components/loginBox';
import logo from '../icon/Alfred_lineWhite.png'

export default function Login() {
  return (
    <div className="login-container">
      <img
        src={logo}
        alt="Login Image"
        className="login-image"
      />
      <LoginBox  /> {/* Utiliser LoginBox ici */}
    </div>
  );
}