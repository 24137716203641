import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import du hook useNavigate
import { ApiUser } from '../api/ApiUser';

export default function LoginBox() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate(); // Initialisation de useNavigate
    const handleRegister = () => {
        if (password !== confirmPassword) {
          alert('Les mots de passe ne correspondent pas.');
          return;
        }
        
        if (firstName && lastName && email && password) {
          // Remplacez par la logique réelle d'inscription
          ApiUser.register( firstName, lastName, email, password )
            .then((response) => {
              navigate('/confirmation-mail');
            })
            .catch((error) => {
              alert('Échec de l\'inscription. Veuillez réessayer.');
            });
        } else {
          alert('Veuillez remplir tous les champs.');
        }
      };
    
      return (
        <div className="login-box">
          <p className="text-Connexion">Inscription</p>
          <p className="signup-text">
            Vous avez déjà un compte ?{' '}
            <button className="signup-button" onClick={() => navigate('/login')}>
              Connectez-vous
            </button>
          </p>
          <input
            type="text"
            placeholder="Nom"
            className="input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)} // Capture du nom
          />
          <input
            type="text"
            placeholder="Prénom"
            className="input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)} // Capture du prénom
          />
          <input
            type="email"
            placeholder="Email"
            className="input"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Capture de l'email
          />
          <input
            type="password"
            placeholder="Mot de passe"
            className="input"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Capture du mot de passe
          />
          <input
            type="password"
            placeholder="Confirmation du mot de passe"
            className="input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)} // Capture de la confirmation du mot de passe
          />
          <button className="connect-button" onClick={handleRegister}>
            Confirmer
          </button>
        </div>
      );
    }