import React from 'react';
import { useLanguage } from '../components/language/LanguageContext';
import translate from '../components/language/Translate';

import EmailCodeConfirmation from './EmailCodeConfirmation';

export default function ConfirmationBox() {
  const { lang, changeLanguage } = useLanguage();

    return (
        <div className="login-box">
            <p className="text-Connexion">{translate("Confirmation du Mail",lang)}</p>
            <p className='resend-text'>
                {translate("Veuillez entrer le code que nous vous avons envoyé. Si vous ne l'avez pas reçu, cliquez",lang)} {' '}
                <button className="resend-button" onClick={() => alert('Inscription')}>
                    {translate("ici",lang)}
                </button>
                .
            </p>
            <EmailCodeConfirmation />
        </div>
    );
}