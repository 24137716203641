import React, { useState, useRef } from 'react';
import { ApiUser } from '../api/ApiUser';
import { useNavigate } from 'react-router-dom'; // Import du hook useNavigate

export default function EmailCodeConfirmation() {
  const [code, setCode] = useState(Array(6).fill('')); // Initialise un tableau de 6 éléments pour le code
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]); // Références pour chaque input
  const navigate = useNavigate(); // Initialisation du hook useNavigate

  const handleChange = (index: number, value: string) => {
    // S'assure que l'utilisateur entre uniquement des chiffres
    if (/^\d$/.test(value) || value === '') {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // Passe automatiquement au champ suivant si un chiffre est entré
      if (value && index < 5) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    // Déplace le focus vers l'input précédent en cas de backspace et si le champ est vide
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = () => {
    const fullCode = code.join('');
    if (fullCode.length === 6) {
      ApiUser.confirmMail(fullCode)
        .then((response) => {
          navigate('/documents');
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert('Veuillez entrer un code à 6 chiffres complet.');
    }
  };

  return (
    <div>
      <div className="code-input-container">
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            maxLength={1} // Limite la saisie à un seul caractère
            ref={(el) => (inputRefs.current[index] = el)} // Référence pour chaque input
            className="code-input-box"
          />
        ))}
      </div>
      <button className="connect-button" onClick={handleSubmit}>
                Confirmer
            </button>
    </div>
  );
}
