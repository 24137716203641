import DocumentSideBar from "../components/DocumentSideBar";
import Offer from "../components/Offer";
import React, { useState, useEffect, ChangeEvent  } from 'react';
import { ApiFile } from '../api/ApiFile'; 
import vector from "../icon/Vector.svg"
import starFill from '../icon/Star Fill.svg'
import starStroke from '../icon/Star Stroke.svg'
import trash from '../icon/Trash.svg'
import oeil from '../icon/Oeil.svg'
import { useNavigate } from 'react-router-dom';
interface Tag {
  name: string;
  value: string;
  id_: string; // L'ID de chaque tag
}

interface Document {
  id: string;
  favori: boolean;
  nom: string;
  date: string;
  typeName: string;
  tags: Tag[]; // Ajouter la liste des tags
}

const UploadDocuments = ({ setDocuments }: { setDocuments: React.Dispatch<React.SetStateAction<Document[]>> }) => {
  const [uploadedDocuments, setUploadedDocuments] = useState<File[]>([]);
  const [documentContents, setDocumentContents] = useState<string[]>([]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      const newDocuments = [...uploadedDocuments, ...Array.from(files)];
      setUploadedDocuments(newDocuments);

      Array.from(files).forEach((file) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target?.result as string;
          setDocumentContents((prev) => [...prev, content]);
        };

        if (file.type.startsWith('image/')) {
          reader.readAsDataURL(file); // Pour les images
        } else if (file.type === 'application/pdf') {
          reader.readAsDataURL(file); // PDF sera traité comme une URL de fichier
        }
      });

      // Mise à jour des documents dans le composant parent
      setDocuments((prev) => [
        ...prev,
        ...Array.from(files).map((file) => ({
          id: file.name,
          nom: file.name,
          date: new Date().toLocaleDateString(),
          favori: false,
          typeName: file.type || 'File',
          tags: [],
        })),
      ]);
    }
  };

  // Fonction pour uploader les documents via l'API
  const handleUploadDocuments = async () => {
    

    try {
      await ApiFile.uploadFiles(uploadedDocuments); // Utilisez votre API pour uploader les fichiers
      alert('Documents uploadés avec succès');
      setUploadedDocuments([]);
    } catch (error) {
      console.error('Erreur lors de l\'upload des documents :', error);
    }
  };

  return (
    <div className="document-container">
      {uploadedDocuments.map((document, index) => (
        <div key={index} className="document-preview">
          {document.type.startsWith('image/') ? (
            <img
              src={documentContents[index]} // Affiche l'image
              alt="Document"
              className="document-content"
            />
          ) : document.type === 'application/pdf' ? (
            <embed
              src={documentContents[index]} // Affiche l'aperçu PDF
              type="application/pdf"
              className="document-content"
            />
          ) : null}
        </div>
      ))}

      <label htmlFor="file-upload" className="upload-button">
        <img
          src="http://localhost:3000/icon/Add File.svg"
          alt="Upload"
          className="upload-icon"
        />
      </label>
      <input
        type="file"
        id="file-upload"
        accept="image/*,application/pdf"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />

      {/* Bouton pour uploader les fichiers */}
      {uploadedDocuments.length > 0 && (
        <button className="upload-submit-button" onClick={handleUploadDocuments}>
          Ajouter
        </button>
      )}
    </div>
  );
};



export default function Home() {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const navigate = useNavigate();

  const openFile = (id: string) => {
    const fileUrl = `https://alfred-app.fr/api-file/file/download/get/id/${id}`;
    window.open(fileUrl, '_blank');
  };


  useEffect(() => {
    // Fetch les fichiers à partir de l'API
    const fetchDocuments = async () => {
      try {
        const fetchedDocuments = await ApiFile.getFile();

        // Mapper les documents dans le format que vous utilisez, avec les tags
        const mappedDocuments = fetchedDocuments.map((doc: any) => ({
          id: doc._id,
          favori: doc.favorie, // Par défaut, pas de favori
          nom: doc.name,
          date: new Date().toLocaleDateString(), // Vous pouvez ajuster cette date
          typeName: doc.typeName,
          tags: doc.tags.map((tag: any) => ({
            name: tag.name,
            value: tag.value,
          })),
        }));
        setDocuments(mappedDocuments); // Mise à jour de l'état avec les documents
      } catch (error) {
        console.error("Erreur lors du fetch des documents :", error);
      }
    };

    fetchDocuments();
  }, []);



  const handleSearchFocus = () => {
    setIsSearchActive(true);
  };

  const handleSearchBlur = () => {
    if (searchTerm.length == 0){
      setIsSearchActive(false);
    }
  };

  const toggleFavori = (id: string) => {
    ApiFile.ChangeFavorie(id);
    setDocuments(documents.map(doc =>
      doc.id === id ? { ...doc, favori: !doc.favori } : doc
    ));
  };

  const handleDeleteDocument = (id: string) => {
    // Suppression du document côté serveur
    ApiFile.deleteFile(id)
      .then(() => {
        // Mise à jour de l'état local après la suppression
        setDocuments((prevDocuments) => prevDocuments.filter((doc) => doc.id !== id));
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression du document :", error);
      });
  };

  const filteredDocuments = documents.filter((doc) => {
    const matchesName = doc.nom.toLowerCase().includes(searchTerm);
    const matchesType = doc.typeName.toLowerCase().includes(searchTerm);
    const matchesTags = doc.tags.some((tag) =>
      tag.value.toLowerCase().includes(searchTerm)
    );
    return matchesName || matchesType || matchesTags;
  });

  return (
    <div className="home-page">
      <div className="main-box-document">
        <div className="top-page">
          <div className="page-title">Document</div>
          <button className="add-button" onClick={() => setIsModalOpen(true)}>
            Ajouter un document
          </button>
        </div>
        {!isSearchActive && <Offer />}
        <div className="search-bar-wrapper">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="search-icon"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width="24"
            height="24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35"
            />
          </svg>
          <input
            type="text"
            className="search-bar"
            placeholder="Rechercher un document"
            onFocus={handleSearchFocus}
            onBlur={handleSearchBlur}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <ul className="documentList">
          {filteredDocuments.map((doc) => (
            <li key={doc.id} className="document-box">
                <div className="Document-text-container">
                  <img src={vector} alt="Documents" className="documentIcon" />
                  <div className="document-Name" onClick={() => navigate('/documents/'+doc.id)}>{doc.nom}</div>
                </div>
              <div className="end-part-document">
                <div>{doc.date}</div>
                <div className="Document-Icon-container">
                  <button className="favoriButton" onClick={() => toggleFavori(doc.id)}>
                    {doc.favori ? (
                      <img src={starFill} alt="Filled Star" className="favoriIcon" />
                    ) : (
                      <img src={starStroke} alt="Outlined Star" className="favoriIcon" />
                    )}
                  </button>
                  <button className="favoriButton" onClick={() => handleDeleteDocument(doc.id)}>
                    <img src={trash} alt="Filled Star" className="favoriIcon" />
                  </button>
                  <button className="favoriButton" onClick={() => openFile(doc.id)}>
                    <img src={oeil} alt="Filled Star" className="favoriIcon" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <DocumentSideBar documents={documents} setDocuments={setDocuments}/>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="modal-title">Ajouter un document</h2>
            <UploadDocuments setDocuments={setDocuments} />
            <button className="close-button" onClick={() => setIsModalOpen(false)}>
              <img src="http://localhost:3000/icon/close.svg" alt="Close" className="favoriIcon" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}