import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home';
import LandingPage from './pages/landingPage';
import Authentification from './pages/login';
import Register from './pages/register';
import ResetPassword from './pages/resetPassword';
import Profile from './pages/profile';
import ScenarioList from './pages/scenario/index';
import Scenario from './pages/scenario/scenario';
import NewScenario from './pages/scenario/newScenario'
import ConfirmatioMail from './pages/ConfirmatioMail';
import Disclaimer from './pages/disclaimer'
import DocumentDetail from './pages/documentDetai';
import SidebarLayout from './utils/SidebarLayout';
import Confirm2fa from './pages/Confirm2fa';
import { LanguageProvider } from './components/language/LanguageContext';
import Subscription from './pages/Subscription';

// A mock function to simulate authentication status
const isAuthenticated = (): boolean => {
  return !!localStorage.getItem('authToken');
};

// A wrapper component to protect private routes
const PrivateRoute: React.FC<{ element: JSX.Element }> = ({ element }) => {
  // return isAuthenticated() ? element : <Navigate to="/login" />;
  return element;
};

const AppRouter: React.FC = () => {
  return (
    <LanguageProvider>
      <Router>
        <Routes>
          {/* No sidebar and no authentication needed */}
          <Route path="/" element={<Disclaimer />} />
          <Route path="/login" element={<Authentification />} />
          <Route path="/login2FA" element={<Confirm2fa />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/disclaimer" element={<Disclaimer />} />

          {/* Protected routes with Sidebar */}
          <Route
            path="/documents"
            element={<PrivateRoute element={<SidebarLayout><Home /></SidebarLayout>} />}
          />
          <Route
            path="/documents/:id"
            element={<PrivateRoute element={<SidebarLayout><DocumentDetail /></SidebarLayout>} />}
          />
          <Route
            path="/scenarios"
            element={<PrivateRoute element={<SidebarLayout><ScenarioList /></SidebarLayout>} />}
          />
          <Route
            path="/scenarios/:id"
            element={<PrivateRoute element={<SidebarLayout><Scenario /></SidebarLayout>} />}
          />
          <Route
            path="/scenarios/nouveau"
            element={<PrivateRoute element={<SidebarLayout><NewScenario /></SidebarLayout>} />}
          />
          <Route
            path="/profile"
            element={<PrivateRoute element={<SidebarLayout><Profile /></SidebarLayout>} />}
          />
          <Route
            path="/subscription"
            element={<PrivateRoute element={<Subscription />} />}
            >
            </Route>

          {/* Protected route without Sidebar */}
          <Route
            path="/confirmation-mail"
            element={<PrivateRoute element={<ConfirmatioMail />} />}
          />

          {/* Fallback route to redirect to disclaimer */}
          <Route path="*" element={<Navigate to="/disclaimer" replace />} />
        </Routes>
      </Router>
    </LanguageProvider>
  );
};

export default AppRouter;
