import React from 'react';

const DetailStockage = () => {
  const storageData = [
    { category: 'Images', size: 30 },  // GB
    { category: 'PDFs', size: 50 },    // GB
    { category: 'Videos', size: 20 },  // GB
    { category: 'Others', size: 20 },  // GB
  ];

  // Calculate the total size of all categories
  const totalSize = storageData.reduce((acc, item) => acc + item.size, 0);

  // Function to calculate the path for a pie slice
  const calculatePieSlicePath = (startAngle: number, endAngle: number, radius: number): string => {
    const startX = radius + radius * Math.cos(Math.PI * startAngle / 180);
    const startY = radius + radius * Math.sin(Math.PI * startAngle / 180);
    const endX = radius + radius * Math.cos(Math.PI * endAngle / 180);
    const endY = radius + radius * Math.sin(Math.PI * endAngle / 180);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return `M${radius},${radius} L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag} 1 ${endX},${endY} Z`;
  };

  const radius = 100; // Adjust the radius for your chart size
  const centerX = 120;
  const centerY = 120;
  
  let currentAngle = 0;

  // Create pie slices based on storage data
  const paths = storageData.map(item => {
    const sliceAngle = (item.size / totalSize) * 360;
    const path = calculatePieSlicePath(currentAngle, currentAngle + sliceAngle, radius);
    currentAngle += sliceAngle;
    return path;
  });

  // Slice colors (you can customize these)
  const colors = ['#FF5733', '#33FF57', '#3357FF', '#57FF33'];

  return (
    <svg width="240" height="240" viewBox="0 0 240 240">
      {paths.map((path, index) => (
        <path key={index} d={path} fill={colors[index]} />
      ))}
    </svg>
  );
};

export default DetailStockage;
