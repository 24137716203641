import { useState, useEffect } from 'react';
import { ApiFile } from '../api/ApiFile';
import close from '../icon/Close.svg';
import DetailStockagePieChart from './DetailStockage';

const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export default function StorageBox() {
    const [nbFiles, setNbFiles] = useState(0);
    const [totalStorageUsed, setTotalStorageUsed] = useState(0);
    const [maxStorage, setMaxStorage] = useState(0);
    const [storagePercent, setStoragePercent] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const fetchStorageInfo = async () => {
            const storageData = await ApiFile.getStorage();
            setNbFiles(storageData.TotalFile);
            setTotalStorageUsed(storageData.TotalSizeUsed);
            setMaxStorage(storageData.maxSize);
            setStoragePercent((storageData.TotalSizeUsed / storageData.maxSize) * 100);
            const data = storageData.data;
            console.log(data);
        };
        fetchStorageInfo();
    }, []);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <div className="storageBox">
            <div className="storageText">Stockage</div>
            <div className="fileCount">{nbFiles} fichiers</div>
            <div className="storageBarContainer" onClick={openModal}>
                <div className="storageBar" style={{ width: `${storagePercent}%` }}></div>
            </div>
            <div className="storageInfo">
                <div>{formatBytes(totalStorageUsed)} </div>
                <div>{formatBytes(maxStorage)} </div>
            </div>
            <button className="textButton">Gérer mon abonnement</button>

            {/* Modal */}
            {showModal && (
                <div className="modalOverlay" onClick={closeModal}>
                    <div className="modalContent" onClick={(e) => e.stopPropagation()}>
                        <img
                            src={close}
                            alt="Close"
                            className="closeIcon"
                            onClick={closeModal}
                        />

                        <DetailStockagePieChart></DetailStockagePieChart>
                        <h2>Storage Details</h2>
                        <p>Used Storage: {formatBytes(totalStorageUsed)}</p>
                        <p>Maximum Storage: {formatBytes(maxStorage)}</p>
                    </div>
                </div>
            )}
        </div>
    );
}
