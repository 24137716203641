import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation  } from 'react-router-dom'; // Importation du hook useNavigate
import logo from "../icon/Alfred_lineWhite.png"
import files from "../icon/Files.svg"
import bounce from "../icon/Bounce.svg"
import user from "../icon/User.svg"
import settingslogo from "../icon/Settings.svg"
import bell from "../icon/Bell.svg"

export default function Sidebar() {
  const navigate = useNavigate(); // Hook pour naviguer
  const location = useLocation();
  const getFirstPathSegment = (pathname: string) => {
    const segments = pathname.split('/').filter(Boolean); // Diviser et filtrer les segments vides
    return segments[0] || ''; // Retourner le premier segment ou une chaîne vide
  };
  const firstSegment = getFirstPathSegment(location.pathname);
  console.log(firstSegment)
  const [selected, setSelected] = useState(''); // État pour stocker le bouton sélectionné

  useEffect(() => {
    const firstSegment = getFirstPathSegment(location.pathname);
    
    if (firstSegment === 'documents') {
      setSelected('Documents');
    } else if (firstSegment === 'scenarios') {
      setSelected('Scénarios');
    } else if (firstSegment === 'profile') {
      setSelected('Mon Compte');
    } else if (firstSegment === 'settings') {
      setSelected('Réglages');
    } else if (firstSegment === 'notifications') {
      setSelected('Notifications');
    }
  }, [location.pathname]);

  const handleNavigation = (path: string, label: string) => {
    setSelected(label); // Mettre à jour l'élément sélectionné
    navigate(path); // Changer de page avec useNavigate
  };

  return (
    <>
    <div className="sidebar">
      <div className="logo" onClick={() => navigate('/documents')}>
        <button className='logo-btn'>
        <img
          src={logo}
          alt="Logo"
          className="logo-image"
        />
        </button>

      </div>

      <ul className="nav-links">
        <li>
          <button
            className={`nav-button ${selected === 'Documents' ? 'active' : ''}`}
            onClick={() => handleNavigation('/Documents', 'Documents')}
          >
            <img src={files} alt="Documents" />
            Documents
          </button>
        </li>
        <li>
          <button
            className={`nav-button ${selected === 'Scénarios' ? 'active' : ''}`}
            onClick={() => handleNavigation('/scenarios', 'Scénarios')}
          >
            <img src={bounce} alt="Scénarios" />
            Scénarios
          </button>
        </li>
      </ul>

      <hr className="divider" />

      <ul className="nav-links">
        <li>
          <button
            className={`nav-button ${selected === 'Mon Compte' ? 'active' : ''}`}
            onClick={() => handleNavigation('/profile', 'Mon Compte')}
          >
            <img src={user} alt="Mon Compte" />
            Mon Compte
          </button>
        </li>
        <li>
          <button
            className={`nav-button ${selected === 'Réglages' ? 'active' : ''}`}
            // onClick={() => handleNavigation('/settings', 'Réglages')}
          >
            <img src={settingslogo} alt="Réglages" />
            Réglages
          </button>
        </li>
        <li>
          <button
            className={`nav-button ${selected === 'Notifications' ? 'active' : ''}`}
            // onClick={() => handleNavigation('/notifications', 'Notifications')}
          >
            <img src={bell} alt="Notifications" />
            Notifications
          </button>
        </li>
      </ul>
    </div>



    <div className="mobile-nav">
        <button onClick={() => handleNavigation('/documents', 'Documents')}>
          <img src={files} alt="Documents" />
          <span>Documents</span>
        </button>
        <button onClick={() => handleNavigation('/scenarios', 'Scénarios')}>
          <img src={bounce} alt="Scénarios" />
          <span>Scénarios</span>
        </button>
        <button onClick={() => handleNavigation('/profile', 'Mon Compte')}>
          <img src={user} alt="Mon Compte" />
          <span>Mon Compte</span>
        </button>
        <button>
          <img src={settingslogo} alt="Réglages" />
          <span>Réglages</span>
        </button>
        <button>
          <img src={bell} alt="Notifications" />
          <span>Notifications</span>
        </button>
      </div>
</>

  );
}
