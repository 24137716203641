import React from 'react'
import logo from "../icon/Alfred_lineWhite.png"
import Confirmation2faBox from '../components/Confirm2faBox'


export default function Confirm2fa () {
    return (
    <div className="login-container">
      <img
        src={logo}
        alt="Confirme"
        className="login-image"
      />
      <Confirmation2faBox  /> {/* Utiliser LoginBox ici */}
    </div>
    )
}
