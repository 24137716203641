/* istanbul ignore file */

import Cookies from 'js-cookie';

interface RegisterData {
    name: string;
    lastname: string;
    email: string;
    password: string;
    phonenumber?: string; // Propriété facultative
    profilePhoto?: string; // Propriété facultative pour l'image de profil
}

interface UpdateUserData {
    name?: string;
    lastname?: string;
    email?: string;
    phone?: string; // All fields are optional now
}

async function FetchUser(url: string, method: string, contentType: string, csrfToken: string, body?: any) {
    const options: RequestInit = {
        method,
        headers: {
            'Authorization': "Bearer " + Cookies.get('authToken'),
            'X-CSRF-Token': csrfToken,
            'x-mobile-app': 'true'
        },
        credentials: 'include',
        body: null
    };

    if (body) {
        if (contentType === 'multipart/form-data') {
            options.body = body;
        } else {
            options.headers = { ...options.headers, 'Content-Type': contentType };
            options.body = JSON.stringify(body);
        }
    }
    const res = await fetch(`https://alfred-app.fr/api-user/${url}`, options);

    console.log(`[API] [${new Date().toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}] [${method}] /${url} (${res.status})`);
    const json = await res.json();
    console.log('json: ', json)
    if (json.error) {
        throw new Error(json.message);
    }

    return json;
}

export class ApiUser {
    static async login(email: string, password: string) {
        try {
            const json = await FetchUser('login', 'POST', 'application/json', "", {
                email: email,
                password: password
            });
            console.log(json.body)
            Cookies.set('authToken', json.token, { expires: 7 });
            Cookies.set('refreshToken', json.refreshToken, { expires: 7 });
            Cookies.set('idUser', json.id, { expires: 7 });
            return json;
        } catch (error) {
            console.error('Error during login:', error);
            throw error; // Rethrow the error to propagate it to the calling function
        }
    }



    static async register(
        name: string,
        lastname: string,
        email: string,
        password: string,
        phonenumber?: string,
        profilePhoto?: string
    ) {
        try {
            const registerData: RegisterData = {
                name: name,
                lastname: lastname,
                email: email,
                password: password
            };

            if (phonenumber) {
                registerData.phonenumber = phonenumber; // Ajouter phonenumber si fourni
            }
            if (profilePhoto) {
                registerData.profilePhoto = profilePhoto; // Ajouter profilePhoto si fourni
            }
            const res = await FetchUser('register', 'POST', 'application/json', "", registerData);
            Cookies.set('authToken', res.accessToken, { expires: 7 });
            Cookies.set('refreshToken', res.refreshToken, { expires: 7 });
            Cookies.set('idUser', res._id, { expires: 7 });
        } catch (error) {
            console.error('Error during register:', error);
            throw error; // Rethrow the error to propagate it to the calling function
        }
    }

    static async confirmMail(pin: string) {
        console.log(Cookies.get('authToken'))
        const res = await FetchUser(`verify/${pin}`, 'GET', 'application/json', "");
        return res;
    }

    static async confirm2FA(pin: string,) {
        console.log('user: ', Cookies.get('idUser'));
        console.log(pin);
        const res = await FetchUser(`confirm2FA`, 'POST', 'application/json', "", {
            UserID: Cookies.get('idUser'),
            code: pin
        });
        if (res.response != '')
        Cookies.set('authToken', res.token, { expires: 7 });
        Cookies.set('refreshToken', res.refreshToken, { expires: 7 });
        console.log(res)
        return (res);
    }

    static async Set2FA(enable: Boolean,) {
        console.log(Cookies.get('idUser'))
        const res = await FetchUser(`2FA`, 'POST', 'application/json', "", {
            enable2FA: enable
        });
        console.log(res)
    }

    static async SetNewPasswordPassword(token: string, newPassword: string) {
        const res = await FetchUser(`recover-mdp`, 'POST', 'application/json', "", {
            code: token,
            newPassword: newPassword
        });
    }


    static async resetPassword(mail: string) {
        console.log(Cookies.get('idUser'))
        const res = await FetchUser(`reset-password`, 'POST', 'application/json', "", {
            email: mail
        });
        console.log(res)
    }


    static async getUser() {
        const res = await FetchUser(`user`, 'GET', 'application/json', "");
        return res;
    }

    static async updateUser(
        name?: string,
        lastname?: string,
        email?: string,
        phone?: string
    ) {
        try {
            // Only include fields that are not undefined
            const updateData: UpdateUserData = {};
            if (name) updateData.name = name;
            if (lastname) updateData.lastname = lastname;
            if (email) updateData.email = email;
            if (phone) updateData.phone = phone;

            // Make the API call with the optional fields
            const res = await FetchUser('user', 'PUT', 'application/json', "", updateData);
            console.log('User data updated successfully:', res);
            return res;
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    }
}