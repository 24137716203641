import React, { useState, useEffect } from 'react';
import BackArrow from '../../../src/icon/arrow-back.svg';
import { ApiFile } from '../../api/ApiFile';
import { useNavigate, useParams } from 'react-router-dom';

export default function Scenario() {
  const [mandatoryDocuments, setMandatoryDocuments] = useState<any[]>([]);
  const [optionalDocuments, setOptionalDocuments] = useState<any[]>([]);
  const { id } = useParams();
  const [Name, setName] = useState<string>('');
  const IsPerso: Boolean = isNaN(Number(id))
  const navigate = useNavigate();

  const HandleDownloadFiles = async () => {
    try {
      const allDocumentIds = [
        ...mandatoryDocuments.filter(doc => doc.isInDatabase).map(doc => doc.id),
        ...optionalDocuments.filter(doc => doc.isInDatabase).map(doc => doc.id),
      ];

      if (allDocumentIds.length > 0) {
        await ApiFile.DownloadScenario(allDocumentIds);
      }
    } catch (error) {
      console.log(error);
      alert('Erreur lors du téléchargement des fichiers.');
    }
  };

  
  useEffect(() => {
    const fetchScenario = async () => {
      if (id) {
        try {
          // Fetch scenarios data from the API
          const response = await ApiFile.getOneSenario(id);
          setName(response.name)

          const Files = response.files;
          const mandatoryDocs = Files.filter((file: any) => file.isNeeded);
          const optionalDocs = Files.filter((file: any) => !file.isNeeded);

          setMandatoryDocuments(mandatoryDocs);
          setOptionalDocuments(optionalDocs);
          console.log('file:', Files)
        } catch (error) {
          navigate('/scenarios')
        }
      } else {
        navigate('/scenarios')
      }
    };
    fetchScenario();
  }, [id]);

  const ToggleDelete = async () => {
    if (id) {
      ApiFile.deleteScenario(id);
    }
    navigate('/scenarios')
  }


  return (
    <div className='scenarioDetail-main-page'>
      <div className='scenarioDetail-page'>
        <div className='scenarioDetail-top-page'>
          {/* Right-side: back arrow and page title */}
          <div className="right-side" onClick={() => navigate('/scenarios')}>
            <div className='back-arrow' >
              <img src={BackArrow} alt="Back arrow" style={{ width: '24px', height: '24px', color: 'white' }} />
            </div>
            <div className='page-title'>{Name}</div>
          </div>

          {/* Left-side: buttons */}
          <div className="left-side">
            <div className='scenarioDetail-add-button' onClick={HandleDownloadFiles}>Télécharger mon dossier</div>
            {/* {IsPerso && <div className='scenarioDetail-add-button-dot'>...</div>} */}

            {IsPerso && (
              <button className="scenarioDetail-delete-button" onClick={ToggleDelete}>
                Supprimer
              </button>
            )}

          </div>
        </div>

        {/* Display Mandatory Documents */}
        <div className="scenarios-container">
          <div className='page-title'>Documents obligatoires</div>

          <div className="scenario-detail-document-container">
            {mandatoryDocuments.map((document, index) => (
              <div className="document-row" key={index}>
                <div className="document-text">
                  <div className="document-title">{document.name}</div>
                  {document.description && (
                    <div className="document-description">{document.description}</div>
                  )}
                </div>
                {document.isInDatabase ? (
                  <div className="document-status valid">Validé</div>
                ) : (
                  <div className="document-status upload">
                    <button className="scenario-detail-upload-button">Parcourir et joindre</button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Display Optional Documents */}
          <div className='page-title'>Documents facultatifs</div>
          <div className="scenario-detail-document-container">
            {optionalDocuments.map((document, index) => (
              <div className="document-row" key={index}>
                <div className="document-text">
                  <div className="document-title">{document.name}</div>
                </div>
                {document.isInDatabase ? (
                  <div className="document-status valid">Validé</div>
                ) : (
                  <div className="document-status upload">
                    <button className="scenario-detail-upload-button">Parcourir et joindre</button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
