import React from 'react';

import EmailCodeConfirmation from './EmailCodeConfirmation';

export default function ConfirmationBox() {
    return (
        <div className="login-box">
            <p className="text-Connexion">Confirmation Du Mail</p>
            <p className='resend-text'>
                Veuillez entrer le code que nous vous avons envoyé. Si vous ne l'avez pas reçu, cliquez {' '}
                <button className="resend-button" onClick={() => alert('Inscription')}>
                    ici
                </button>
                .
            </p>
            <EmailCodeConfirmation />

        </div>
    );
}